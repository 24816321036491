import { Row } from "@tanstack/react-table";
import { useMemo } from "react";
import { BondDto } from "models/Bond";
import { useRouter } from "next/router";
import Button from "components/ui/Button";
import consts from "utils/consts";
import { DateWithIcon } from "components/ui/icons/Date";
import { StatusBadge, StatusBadgeColor } from "components/ui/Badge";
import { ExplorerAccountLink } from "components/ui/Link";

const getStatusColor = (status: BondDto["status"]): StatusBadgeColor => {
  switch (status) {
    case "recorded":
    case "distributing":
    case "collecting":
      return "yellow";
    default:
      return "green";
  }
};

const useBondsTableColumns = () => {
  const router = useRouter();

  const columns = useMemo(
    () => [
      {
        id: "isin",
        header: "ISIN",
        accessorKey: "ISIN",
        size: 150,
      },
      {
        id: "name",
        header: "Name",
        accessorKey: "token.name",
        size: 150,
        cell: ({ row }: { row: Row<BondDto> }) => row.original.token.name,
      },
      {
        id: "value",
        header: "Value",
        accessorKey: "value",
        size: 150,
        cell: ({ row }: { row: Row<BondDto> }) => 
          (row.original.nominal_value * row.original.token.supply).toFixed(2).replace('.00', '') + " " + consts.defaultCurrencySymbol,
      },
      {
        id: "wallet",
        header: "StandBy Wallet Amount",
        accessorKey: "value",
        size: 150,
        cell: ({ row }: { row: Row<BondDto> }) => `${row.original?.balance} ${consts.defaultCurrencySymbol}`,
      },
      {
        id: "walletAddress",
        header: "StandBy Wallet Address",
        accessorKey: "value",
        size: 150,
        cell: ({ row }: { row: Row<BondDto> }) => 
          <span className="flex">
            {row.original?.token.warmAccount.address}
            <ExplorerAccountLink className="ml-2" account={row.original?.token.warmAccount.address} />
          </span> ,
      },
      {
        id: "interestRate",
        header: "Interest Rate",
        accessorKey: "annual_coupon_rate",
        size: 150,
        cell: ({ row }: { row: Row<BondDto> }) => row.original.annual_coupon_rate + "%",
      },
      {
        id: "maturationDate",
        header: "Maturity Date",
        accessorKey: "maturity_date",
        size: 150,
        cell: ({ row }: { row: Row<BondDto> }) => <DateWithIcon date={row.original.maturity_date} />
      },
      {
        id: "status",
        header: "Status",
        accessorKey: "status",
        size: 150,
        cell: ({ row }: { row: Row<BondDto> }) => <StatusBadge color={getStatusColor(row.original.status)} text={row.original.status} />
      },
      {
        id: "action",
        header: "",
        accessorKey: "action",
        size: 80,
        cell: ({ row }: { row: Row<BondDto> }) => {
          const bond = row.original;
          return (
            <div className="flex h-[30px] justify-end">
              {(bond.status === "recorded" || bond.status === "distributing") && (
                <Button
                  className="h-[30px] w-[80px]"
                  onClick={(event) => {
                    event.stopPropagation();
                    router.push(`issuance/distribute/${bond.id}`);
                  }}
                >
                  Distribute
                </Button>
              )}
            </div>
          );
        },
      },
    ],
    [router]
  );

  return columns;
};

export default useBondsTableColumns;
